<template>
  <BaseSelect
    v-model="bracketId"
    v-bind="$attrs"
    :label="label"
    label-key="name"
    value-key="id"
    :readonly="appIsMobile"
    :list-position="listPosition"
    :options="brackets"
    :placeholder="$t('tournaments.select_bracketType')"
  >
  </BaseSelect>
</template>

<script>
import BaseSelect from '@components/BaseComponents/Form/Select.vue';

export default {
  name: 'BracketSelect',
  components: {
    BaseSelect,
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    listPosition: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    idGame: {
      type: [Number, String],
      default: '',
    },
  },
  computed: {
    ...mapState('tournaments', ['availableSystems']),
    ...mapGetters('tournaments', ['getTournamentSettings']),
    ...mapGetters('application', ['appIsMobile']),

    bracketId: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    brackets() {
      if (this.idGame) {
        const systemIds =
          this.getTournamentSettings(this.idGame)?.systems || [];
        return systemIds
          .map((id) => this.availableSystems?.[id])
          .filter((item) => item);
      }
      return Object.values(this.availableSystems);
    },
  },
};
</script>
