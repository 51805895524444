<template>
  <div class="edit-avatar">
    <pvp-btn
      variant="clear"
      class="avatar"
      :disabled="!canEdit"
      @click="toggleModal(true)"
    >
      <slot />
      <span
        v-if="canEdit"
        :class="['avatar-hover', { round: isRound }]"
      >
        <Icon name="photo-camera" />
        <span class="text">{{ $t('uploader.update') }}</span>
      </span>
    </pvp-btn>
    <pvp-modal v-model="isOpen" width="580">
      <template slot="modal-title">{{
        $t('profile.edit_avatar_update')
      }}</template>

      <steps :step="step" :counts="2" />

      <template v-if="step === 1">
        <files-uploader
          v-model="files"
          :allowed-formats="allowedFormats"
          :max-file-size="maxFileSize"
          @input="setFilesUploaderError"
        >
          <template v-if="helperText" slot="helper-text">
            {{ helperText }}
          </template>
        </files-uploader>

        <template
          v-if="uploadedUrl && isFilesUploaderValid"
          slot="modal-footer"
        >
          <pvp-btn @click="setStep(2)">{{
            $t('global.next')
          }}</pvp-btn>
        </template>
      </template>

      <template v-if="step === 2">
        <image-cropper
          class="cropper"
          :src="uploadedUrl"
          :width="width"
          :height="height"
          :is-round="isRound"
          @result="afterCropping"
        />
        <div v-if="error" class="error">{{ error }}</div>
        <template slot="modal-footer">
          <loader v-if="isLoading" />
          <template v-else>
            <pvp-btn variant="secondary" @click="setStep(1)">
              {{ $t('global.back') }}</pvp-btn
            >
            <pvp-btn @click="submitAvatar">
              {{ $t('global.done') }}</pvp-btn
            >
          </template>
        </template>
      </template>
    </pvp-modal>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
import Steps from '@components/BaseComponents/Steps.vue';
import FilesUploader from '@components/BaseComponents/FilesUploader.vue';
import ImageCropper from '@components/BaseComponents/ImageCropper.vue';

export default {
  name: 'EditLogo',
  components: {
    ImageCropper,
    FilesUploader,
    Steps,
    Icon,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    id: {
      type: Number,
      default: null,
    },
    hash: {
      type: String,
      default: '',
    },
    isRound: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 250,
    },
    height: {
      type: Number,
      default: 250,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    allowedFormats: {
      type: Array,
      default: () => ['jpg', 'jpeg', 'png'],
    },
    maxFileSize: {
      type: Number,
      default: 0,
    },
    helperText: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    files: [],
    step: 1,
    isOpen: false,
    cropResult: {},
    startFilesUpload: false,
    isLoading: false,
    error: '',
    filesUploaderError: '',
  }),
  computed: {
    uploadedUrl() {
      return this.files?.[0]?.src;
    },
    isFilesUploaderValid() {
      return this.filesUploaderError === '';
    },
  },
  methods: {
    setFilesUploaderError(data) {
      this.filesUploaderError = data[0].error;
    },
    toggleModal(isOpen) {
      if (this.canEdit) {
        this.isOpen = isOpen;
        this.files = [];
        this.setStep(1);
      }
    },

    setStep(step) {
      this.step = step;
      this.error = '';
    },

    afterCropping(result) {
      this.cropResult = result;
    },

    submitAvatar() {
      this.error = '';
      this.isLoading = true;

      const formData = new FormData();
      formData.append('file', this.files?.[0]?.file);
      formData.append('type', this.type);
      if (this.hash) {
        formData.append('hash', this.hash);
      }
      if (this.id) {
        formData.append('id', this.id);
      }
      Object.entries(this.cropResult).forEach(([key, value]) =>
        formData.append(key, value),
      );

      api
        .post('/file/uploadandresize', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((data) => {
          this.$emit('updated', data);
          this.isOpen = false;
        })
        .catch(() => {
          this.error = this.$t('save.error');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  &:hover .avatar-hover {
    display: flex;
  }

  &-hover {
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(black, 0.7);
    color: white;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    &.round {
      border-radius: 50%;
    }
  }

  .user-avatar {
    width: 100%;
    height: 100%;
  }

  .text {
    @include max-laptop() {
      display: none;
    }
  }
}

.text {
  margin-top: 5px;
  font-size: 14px;
}

.steps {
  max-width: 230px;
  margin: 0 auto 30px;
}

.button-secondary,
.button-primary {
  @include min-tablet() {
    width: 160px;

    & + & {
      margin-left: 12px;
    }
  }
  @include max-tablet() {
    width: 100%;

    & + & {
      margin-top: 12px;
    }
  }
}

.cropper {
  @include min-tablet() {
    max-width: 388px;
    margin: 0 auto;
  }
  @include max-tablet() {
    margin: 0 -12px;
  }
}

.error {
  text-align: center;
  font-size: 12px;
  margin-top: 1em;
  color: $orangey-red;
}
</style>
